import { closePlayerStates } from './helpers';
/**
 * Actions for shared player state
 */
export function createPlayerActions(set) {
    return {
        setPlayerState: (playerState) => {
            set(({ playerStates }) => {
                const widgetId = playerState.widget_id;
                return {
                    playerStates: {
                        ...closePlayerStates(playerStates, playerState),
                        [widgetId]: playerState,
                    },
                };
            }, false, 'playerStateSet');
        },
        unsetPlayerState: (widgetId) => {
            set((state) => {
                const { [widgetId]: _, ...playerStates } = state.playerStates || {};
                return {
                    playerStates,
                };
            }, false, 'playerStateUnset');
        },
        /** Livestream relate global actions */
        setLivestreamJoinEventTracked: (videoId) => {
            set((state) => {
                return {
                    isLivestreamJoinEventTrackedByVideoId: {
                        ...state.isLivestreamJoinEventTrackedByVideoId,
                        [videoId]: true,
                    },
                };
            }, false, 'setLivestreamJoinEventTracked');
        },
        unsetLivestreamJoinEventTracked: (videoId) => {
            set((state) => {
                return {
                    isLivestreamJoinEventTrackedByVideoId: {
                        ...state.isLivestreamJoinEventTrackedByVideoId,
                        [videoId]: false,
                    },
                };
            }, false, 'unsetLivestreamJoinEventTracked');
        },
        setUserMuted: (isUserMuted) => {
            set({ isUserMuted }, false, 'setUserMuted');
        },
    };
}
