import { mergeVariables } from '../tools/mergeVariables';
import { theme as defaultTheme } from './default';
export const theme = {
    key: 'dark',
    variables: mergeVariables(defaultTheme.variables, [
        ['--font-color', 'var(--white)'],
        ['--player-chat-background', 'rgb(var(--blk2-rgb) / 90%)'],
        ['--player-shop-background', 'rgb(var(--blk2-rgb) / 90%)'],
        //
        ['--player-deck-border-color', '#3F4145'],
        ['--player-deck-list-background', '#3F4145'],
        [
            '--player-deck-item-background',
            'linear-gradient(135deg, rgba(34, 35, 37, 0.9) 0%, rgba(34, 35, 37, 1) 100%)',
        ],
        [
            '--player-deck-item-background-hover',
            'linear-gradient(135deg, rgba(63, 65, 69, 0.81) 0.01%, rgba(63, 65, 69, 0.9) 100%)',
        ],
        ['--player-deck-item-font-color', 'var(--font-color)'],
        ['--player-deck-discount-price-font-color', '#FF4040'],
        ['--player-deck-product-drawer-trigger-foreground', 'var(--white)'],
        ['--player-deck-product-drawer-trigger-background', '#222325'],
        ['--player-interaction-background', 'var(--blk2)'],
        ['--player-interaction-font-color', 'var(--white)'],
        ['--player-modal-background', 'var(--blk2)'],
        ['--player-modal-font-color', 'var(--font-color)'],
        [
            '--player-drawer-background',
            'color-mix(in srgb, var(--blk2) 85%, transparent)',
        ],
        ['--player-drawer-font-color', 'var(--font-color)'],
        // Product Card
        ['--product-card-background', 'rgb(var(--blk2-rgb) / 90%)'],
        ['--product-card-font-color', 'var(--font-color)'],
        ['--product-name-font-color', 'var(--font-color)'],
        ['--product-price-font-color', 'var(--font-color)'],
        ['--player-deck-cta-button-color', 'var(--white)'],
        ['--player-deck-cta-background-color', '#222325'],
    ]),
};
