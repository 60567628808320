import { useMemo } from 'react';
import { useGlobalStore } from '@core/hooks/useGlobalStore';
import { playerStateOpenedSelector } from '@core/player/model';
/**
 * Subscribe to the state of currently opened player to see
 * if it plays the same content as this component.
 */
export const usePlayerStateListener = (appContext, params) => {
    // State of a player that is currently opened (fullscreen/minimized)
    const currentOpenedPlayerState = useGlobalStore(playerStateOpenedSelector);
    const isPlayingCurrentContent = useMemo(() => {
        if (!currentOpenedPlayerState) {
            return false;
        }
        return !!((params.playlist &&
            params.playlist === currentOpenedPlayerState.playlist) ||
            (appContext?.username &&
                !params.playlist &&
                !currentOpenedPlayerState.playlist &&
                currentOpenedPlayerState.channel === appContext?.username));
    }, [currentOpenedPlayerState, params.playlist, appContext?.username]);
    const currentPlayerId = currentOpenedPlayerState?.widget_id || null;
    const currentPlayerLayout = currentOpenedPlayerState?.layout || null;
    const currentPlayerVideoId = currentOpenedPlayerState?.videoId || null;
    return {
        isPlayingCurrentContent,
        currentPlayerId,
        currentPlayerLayout,
        currentPlayerVideoId,
    };
};
