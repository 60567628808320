import typeOf from 'just-typeof'

/**
 * Test for number type value.
 * @param value
 * @returns
 */
function isNumber(value: unknown): boolean {
  return typeOf(value) === 'number'
}

export default isNumber
