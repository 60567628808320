export var PlayerLayout;
(function (PlayerLayout) {
    PlayerLayout["MINIMIZED"] = "minimized";
    PlayerLayout["FULLSCREEN"] = "fullscreen";
    PlayerLayout["DEFAULT"] = "default";
})(PlayerLayout || (PlayerLayout = {}));
export var VideoPlayerOpenReason;
(function (VideoPlayerOpenReason) {
    VideoPlayerOpenReason["CLOSE_ME"] = "close_me";
    VideoPlayerOpenReason["DEFAULT"] = "default";
    VideoPlayerOpenReason["DOCK"] = "dock";
    VideoPlayerOpenReason["CONFIG"] = "config";
    VideoPlayerOpenReason["PROGRAMMATIC"] = "programmatic";
    VideoPlayerOpenReason["INTERACTION"] = "interaction";
})(VideoPlayerOpenReason || (VideoPlayerOpenReason = {}));
export const openReasonPriority = {
    [VideoPlayerOpenReason.CLOSE_ME]: 1,
    [VideoPlayerOpenReason.DEFAULT]: 2,
    [VideoPlayerOpenReason.DOCK]: 3,
    [VideoPlayerOpenReason.CONFIG]: 4,
    [VideoPlayerOpenReason.PROGRAMMATIC]: 5,
    [VideoPlayerOpenReason.INTERACTION]: 6,
};
