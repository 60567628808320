export interface Api_Interaction_Option {
  text: string
  is_correct: boolean
  confetti: string
}

export enum Api_Video_InteractionTypeEnum {
  QUESTION = 'question',
  POLL = 'poll',
  APPOINTMENT = 'appointment',
  QUIZ = 'quiz',
  GIVEAWAY = 'giveaway',
  LINK = 'link',
}

export interface Api_Interaction_Common {
  collect_email: 'required' | 'optional'
  collect_name: 'required' | 'optional'
  collect_phone: 'required' | 'optional'
  id: string
  interactive_url: string | null
  live_stream_id: string
  options: Api_Interaction_Option[]
  prompt: string
  sort_id: number
  tally: number
  video_id: string
  interaction_type: Api_Video_InteractionTypeEnum
}

export interface Api_Giveaway_Interaction extends Api_Interaction_Common {
  giveaway_celebration_emoji: string
  giveaway_description?: string | null
  interaction_type: Api_Video_InteractionTypeEnum.GIVEAWAY
  terms_and_conditions_url: string
  interaction_results?: { id: string; name: string }[]
}

export interface Api_Link_Interaction
  extends Omit<Api_Interaction_Common, 'interactive_url'> {
  interactive_url: string
}

export type Api_Interaction =
  | Api_Interaction_Common
  | Api_Giveaway_Interaction
  | Api_Link_Interaction

export interface IAPI_Create_VideoInteractionResult {
  tally?: Record<string | 'total', number>
}
