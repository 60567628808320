import { PlayerLayout, VideoPlayerOpenReason, } from '@core/player/types';
/**
 * Check if player is open (minimized or fullscreen)
 * @param playerState
 * @returns
 */
export function isPlayerOpen(playerState) {
    return [PlayerLayout.FULLSCREEN, PlayerLayout.MINIMIZED].includes(playerState.layout);
}
/**
 * Close all players except the one that is open (if applicable)
 * @param playerStates
 * @param playerState
 * @returns
 */
export function closePlayerStates(playerStates, playerState) {
    return isPlayerOpen(playerState)
        ? Object.fromEntries(Object.entries(playerStates).map(([widgetId, playerState]) => [
            widgetId,
            {
                ...playerState,
                layout: isPlayerOpen(playerState)
                    ? PlayerLayout.DEFAULT
                    : playerState.layout,
                openReason: isPlayerOpen(playerState)
                    ? VideoPlayerOpenReason.CLOSE_ME
                    : playerState.openReason,
            },
        ]))
        : playerStates;
}
