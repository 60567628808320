import typeOf from 'just-typeof'

/**
 * Test for boolean type value.
 * @param value
 * @returns
 */
function isBoolean(value: unknown): boolean {
  return typeOf(value) === 'boolean'
}

export default isBoolean
