import {
  EnumFunnelTrackingCookies,
  getCookieWithFallback,
  removeCookieAndFallback,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'
import { uuidv4 } from '@embed/helpers'

/**
 * Used for clearing guest id from storage
 */
export const clearGuestId = () => {
  removeCookieAndFallback(EnumFunnelTrackingCookies.FW_GUEST_ID)
}
/**
 *
 */
export const isValidGuestId = (guestId: string) =>
  !!guestId && guestId !== 'null' && guestId !== 'undefined'

/**
 * Set guest id into storage
 */
export const setGuestId = (newGuestId: string) => {
  setCookieWithFallback(EnumFunnelTrackingCookies.FW_GUEST_ID, newGuestId)
}

/**
 * Reading of guest_id from storage
 */
const fetchGuestId = (): string | undefined =>
  getCookieWithFallback(EnumFunnelTrackingCookies.FW_GUEST_ID)?.value

/**
 * Only public facing function to get guest id.
 * Resolve steps:
 *  1. get guest_id from storage
 *  2. create new guest_id and put to storage
 */
export const getGuestId = (): string => {
  const persistedGuestId = fetchGuestId()
  if (persistedGuestId && isValidGuestId(persistedGuestId)) {
    return persistedGuestId
  } else {
    clearGuestId()
  }

  const guestId = uuidv4()
  setGuestId(guestId)
  return guestId
}
