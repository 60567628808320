import { getGlobalConfig } from '@core/globalConfig';
import { yieldToMain } from '@core/helpers/yieldToMain';
import { logError, logInfo } from '@core/logging';
import { pruneNilValues } from '@core/object';
import { parseShareLinkParams } from '@core/sharing';
import { WidgetTypesEnum } from '@core/types';
import { AppContextType } from '@embed/components/helpers';
import { uuidv4 } from '@embed/helpers';
import { defaultApiHost } from '@src/helpers';
const SKIP_KEY = '_fwn_fetchingInitialVideo';
/**
 *  Resolve initial video cascade
 *    1. from url sharelink param
 *    2. from soft PIP player state from local storage
 *
 *  Note: Do not process any of these if we are in a PIP iframe
 */
export async function loadShareLinkOrSoftPIP() {
    await yieldToMain();
    const { isInPIPIframe } = await import('@core/pictureInPicture');
    if (typeof window !== 'undefined' && !isInPIPIframe()) {
        let fwPlayerParam;
        // Check if we have a short url to resolve first
        const initialSearchParams = new URLSearchParams(window.location.search);
        if (initialSearchParams.has('fw_short')) {
            const shortUrl = initialSearchParams.get('fw_short');
            const response = await fetch(`${defaultApiHost}/api/shorteners/${shortUrl}`);
            if (response.ok) {
                const returnedSearchParams = new URLSearchParams(await response.text());
                // Remove the short url param from the original search params to avoid loop
                initialSearchParams.delete('fw_short');
                window.location.search = new URLSearchParams([
                    ...initialSearchParams,
                    ...returnedSearchParams,
                ]).toString();
            }
        }
        try {
            /** Resolve video from url hash or query (shared) */
            fwPlayerParam = parseShareLinkParams(window);
        }
        catch (err) {
            logError(err);
        }
        if (fwPlayerParam && !window[SKIP_KEY]) {
            window[SKIP_KEY] = fwPlayerParam;
            const channel = getGlobalConfig().channel || fwPlayerParam.channel;
            const maxVideos = fwPlayerParam.playlist_id || fwPlayerParam.hashtag ? undefined : 1;
            if (window._fwn.play) {
                if (channel) {
                    const attrs = {
                        appContextType: AppContextType.EMBED_CONTEXT_TYPE,
                        type: WidgetTypesEnum.PROGRAMMATIC,
                        ...pruneNilValues({
                            channel,
                            hashtag: fwPlayerParam.hashtag,
                            max_videos: maxVideos,
                            playlist: fwPlayerParam.playlist_id,
                            video: fwPlayerParam.video_id,
                            widget_id: uuidv4(),
                            start_time: fwPlayerParam.start_time,
                        }),
                    };
                    const opts = {
                        // Initial PIP will be disabled by pip=false
                        autoplay: true,
                        muted: true,
                        pip: false,
                    };
                    window._fwn.play(attrs, opts);
                }
                else {
                    logInfo('Could not play shared video without channel');
                }
            }
        }
        else {
            import('@player/features/pictureInPicture/restoreSoftPIPPlayer').then(({ restoreSoftPIPPlayer }) => {
                restoreSoftPIPPlayer();
            });
        }
    }
}
export const TEST_ONLY = {
    reset() {
        delete window[SKIP_KEY];
    },
};
