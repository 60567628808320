import { useCallback } from 'react';
import { playerStateOpenedSelector } from '@core/player/model';
import { openReasonPriority } from '@core/player/types';
import { getGlobalStore } from '@core/store';
/**
 * Provides callback to check if a widget can claim the player by given reason level.
 * @returns
 */
export function useCanClaimPlayerCallback() {
    return useCallback((reason) => {
        const currentOpenedPlayerState = playerStateOpenedSelector(getGlobalStore().getState());
        return (!currentOpenedPlayerState ||
            openReasonPriority[currentOpenedPlayerState?.openReason || ''] <=
                openReasonPriority[reason]);
    }, []);
}
