import isBoolean from '@core/lodash/isBoolean';
import isNumber from '@core/lodash/isNumber';
import isString from '@core/lodash/isString';
import { parseQueryParams } from '@core/window';
/**
 *
 */
export function getSerializableAppContext(appContext, params, video) {
    const { appContextType, username, guestId, embedFeedConfig, trackingData, trackingDataPixel, } = appContext;
    return {
        params: Object.keys(params).reduce((map, key) => {
            if (isNumber(params[key]) ||
                isString(params[key]) ||
                isBoolean(params[key])) {
                map[key] = params[key];
            }
            return map;
        }, {}),
        appContextType,
        username,
        guestId,
        embedFeedConfig: { ...embedFeedConfig, firstVideoId: video?.id },
        trackingData,
        trackingDataPixel,
    };
}
/**
 *
 */
export const createLinkToEmbedVideoPage = (appContext, params, video) => {
    const url = new URL(`${params.microsites_host || 'https://fw.tv'}/embed/videos/${video?.id}`);
    url.searchParams.append('app_context', JSON.stringify(getSerializableAppContext(appContext, params, video)));
    return url.href;
};
/**
 *
 */
export const abbreviateNumber = (number) => {
    const tier = (Math.log10(number) / 3) | 0;
    if (tier === 0) {
        return String(number);
    }
    const suffix = ['', 'k', 'M', 'G', 'T', 'P', 'E'][tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = number / scale;
    const parts = String(scaled).match(/^([0-9]+)\.?([0-9]+)?$/);
    if (!parts) {
        return '';
    }
    return (`${parts[1]}.${parts[2] ? parts[2][0] : '0'}`.replace(/\.0$/, '') + suffix);
};
/**
 *
 */
export const uuidv4 = () => {
    try {
        // UUID v4 using crypto RNG
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore typing crypto hacks in TS is almost impossible
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (char) => {
            const random = window.crypto.getRandomValues(new Uint8Array(1))[0];
            const value = char ^ (random & (15 >> (char / 4)));
            return value.toString(16);
        });
    }
    catch (error) {
        // Fallback to simple version using Math.random() as RNG
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
            const random = (Math.random() * 16) | 0;
            const value = char === 'x' ? random : (random & 0x3) | 0x8;
            return value.toString(16);
        });
    }
};
/**
 *
 */
export const removeEmojis = (text) => {
    // https://www.regextester.com/106421
    // the following code removes the following characters
    // 0x00A9(copy right symbol),
    // 0x00AE(register symbol),
    // 0x1F000 - 0x1F3FF, 0x1F400 - 0x1F7FF, 0x1F800 - 0x1FBFF (various emoji, symobols and pictographs)
    return typeof text === 'string'
        ? text
            .replace(/(\u00a9|\u00ae|[\u2000-\u2BFF]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '')
            .trim()
        : '';
};
/**
 *
 */
export const detectWordpress = () => {
    const meta = typeof window !== 'undefined' &&
        document.querySelector("meta[name='generator']");
    return (meta &&
        meta.hasAttribute('content') &&
        meta.getAttribute('content').toLowerCase().startsWith('wordpress'));
};
/**
 *
 */
export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;
/**
 *
 */
export const extractStartTimeFromQuerystring = (location) => {
    const { href } = location || {};
    const [, query] = href ? href.split('?') : [];
    const { start_time } = parseQueryParams(query);
    if (start_time) {
        return Number(start_time) || 0;
    }
    return 0;
};
/**
 *
 */
export const extractPageUrlFromQuerystring = (location) => {
    const { href } = location || {};
    const [, query] = href ? href.split('?') : [];
    let { page_url } = parseQueryParams(query);
    if (page_url) {
        // if the href contains page_url, return its page_url
        // remove query if page_url still contains query
        if (String(page_url).includes('?')) {
            page_url = String(page_url).split('?')[0];
        }
        return page_url;
    }
    return null;
};
/**
 *
 */
export const extractPageUrlPwa = (params) => {
    const { location } = params || {};
    const { href } = location || {};
    return href || null;
};
/**
 *
 */
export const isValidEmail = (email) => {
    if (!email) {
        return false;
    }
    return !!email.match(/(.+)@(.+)\.(.+)/);
};
/**
 *
 */
export const stringHasContent = (str) => str.search(/[^ ]+/) !== -1;
/**
 *
 */
export const updateVideoLiveStreamParams = (video, liveStream, forceReplay = false) => {
    const keyHasChanged = (key) => video?.[`live_stream_${key}`] !== liveStream?.[key];
    const keysToCheck = [
        'announcement',
        'chat_enabled',
        'chat_in_replay_enabled',
        'ended_at',
        'playback_url',
        'provider',
        'replay_enabled',
        'replay_url',
        'scheduled_at',
        'started_at',
        'status',
        'viewers_count_enabled',
        'business_privacy_policy_url',
    ];
    if (video &&
        liveStream &&
        video.id === liveStream.video_id &&
        keysToCheck.some((key) => keyHasChanged(key))) {
        const updatedVideo = Object.fromEntries(keysToCheck
            .filter((key) => keyHasChanged(key))
            .map((key) => [`live_stream_${key}`, liveStream[key]]));
        if (forceReplay) {
            updatedVideo.live_stream_replay_enabled = true;
        }
        return updatedVideo;
    }
    return null;
};
