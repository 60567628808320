export var Enum_LiveStream_Provider;
(function (Enum_LiveStream_Provider) {
    Enum_LiveStream_Provider["AGORA"] = "agora";
    Enum_LiveStream_Provider["FACEBOOK"] = "facebook";
    Enum_LiveStream_Provider["INSTAGRAM"] = "instagram";
    Enum_LiveStream_Provider["IVS"] = "ivs";
    Enum_LiveStream_Provider["MUX"] = "mux";
    Enum_LiveStream_Provider["TIKTOK"] = "tiktok";
    Enum_LiveStream_Provider["TWITCH"] = "twitch";
    Enum_LiveStream_Provider["YOUTUBE"] = "youtube";
})(Enum_LiveStream_Provider || (Enum_LiveStream_Provider = {}));
export var Enum_LiveStream_Status;
(function (Enum_LiveStream_Status) {
    Enum_LiveStream_Status["IDLE"] = "idle";
    Enum_LiveStream_Status["ACTIVE"] = "active";
    Enum_LiveStream_Status["PAUSED"] = "paused";
    Enum_LiveStream_Status["REPLAY"] = "replay";
    Enum_LiveStream_Status["COMPLETED"] = "completed";
    Enum_LiveStream_Status["EXPIRED"] = "expired";
    Enum_LiveStream_Status["DISABLED"] = "disabled";
    Enum_LiveStream_Status["ERRORED"] = "errored";
})(Enum_LiveStream_Status || (Enum_LiveStream_Status = {}));
export var Enum_LiveStream_User_type;
(function (Enum_LiveStream_User_type) {
    Enum_LiveStream_User_type["VIEWER"] = "viewer";
    Enum_LiveStream_User_type["HOST"] = "host";
    Enum_LiveStream_User_type["MODERATOR"] = "moderator";
    Enum_LiveStream_User_type["BOT"] = "bot";
    Enum_LiveStream_User_type["AI_ASSISTANT"] = "ai_assistant";
})(Enum_LiveStream_User_type || (Enum_LiveStream_User_type = {}));
export var Enum_LiveStream_Private_Messaging;
(function (Enum_LiveStream_Private_Messaging) {
    Enum_LiveStream_Private_Messaging["ALWAYS_ON"] = "always_on";
    Enum_LiveStream_Private_Messaging["REPLAY_ONLY"] = "replay_only";
    Enum_LiveStream_Private_Messaging["DISABLED"] = "disabled";
})(Enum_LiveStream_Private_Messaging || (Enum_LiveStream_Private_Messaging = {}));
export var Enum_LiveStream_Auto_Response;
(function (Enum_LiveStream_Auto_Response) {
    Enum_LiveStream_Auto_Response["ALWAYS_ON"] = "always_on";
    Enum_LiveStream_Auto_Response["MODERATED"] = "moderated";
    Enum_LiveStream_Auto_Response["DISABLED"] = "disabled";
})(Enum_LiveStream_Auto_Response || (Enum_LiveStream_Auto_Response = {}));
