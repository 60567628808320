import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApiWithParams } from '@core/api/hooks/useApi';
import { customEvent, EventType } from '@core/customEvent';
import { isVideoFeed } from '@core/feed/utils';
import { filterTruthy } from '@core/helpers/filterTruthy';
import { useGlobalStore } from '@core/hooks/useGlobalStore';
import { getHydratedVideosSelector } from '@core/hydration';
import { KEY_FIRST_VIDEOS_RECEIVED, KEY_VIDEOS_STARTED, usePerformance, } from '@core/performance';
import { FeedType } from './types';
const DEFAULT_PAGE = 10;
const updateFeedItemsWithHydratedPartials = (videos, hydratedVideos) => {
    return videos.map((video) => {
        if (!hydratedVideos?.[video.encoded_id]) {
            return video;
        }
        else {
            return {
                ...video,
                ...hydratedVideos[video.encoded_id],
            };
        }
    });
};
/**
 * Hook that returns a feed and a function to load more videos. It sends a
 * request to the API to get the feed if no hydrated feed is provided.
 */
export const useFeedQuery = ({ config, params, feed: hydratedFeed, }) => {
    const configs = useMemo(() => (Array.isArray(config) ? config : [config]), [config]);
    const api = useApiWithParams(params);
    const performance = usePerformance(params);
    const hydratedVideos = useGlobalStore(getHydratedVideosSelector);
    const maxVideos = configs[0].maxVideos;
    const pageSize = useMemo(() => Math.min(DEFAULT_PAGE, maxVideos || Infinity), [maxVideos]);
    const [{ loading, feed, error, config: winningConfig }, setState] = useState({
        loading: !hydratedFeed,
        feed: hydratedFeed || null,
        error: null,
        config: hydratedFeed ? configs[0] : null,
    });
    const createFeedRequests = useMemo(() => {
        const requests = configs.map((config) => {
            switch (config.type) {
                case FeedType.USER_TIMELINE:
                    return (pageSize) => api.createEmbedUserTimelineFeed(config.username ?? '', pageSize, config.firstVideoId);
                case FeedType.USER_TIMELINE_HASHTAG:
                    return (pageSize) => api.createEmbedUserTimelineHashtagFeed(config.username ?? '', config.hashtagExpression, pageSize, config.firstVideoId);
                case FeedType.PRODUCT_SKU:
                    return (pageSize) => api.createEmbedUserProductSKUFeed(config.username ?? '', pageSize, config.skus);
                case FeedType.PLAYLIST:
                    return (pageSize) => api.createEmbedPlaylistFeed(config.playlistId ?? '', pageSize, config.firstVideoId);
                case FeedType.PLAYLIST_COLLECTION:
                    return () => api.createEmbedPlaylistCollectionFeed(params.playlist_collection);
                case FeedType.SHOWROOM_SCHEDULE:
                    return () => api.createShowroomScheduleFeed(params.showroom_id ?? '');
                default:
                    // For completeness, but this case shouldn't be hit
                    return null;
            }
        });
        return filterTruthy(requests);
    }, [configs, api, params.playlist_collection, params.showroom_id]);
    const createFeed = useCallback(async () => {
        try {
            if (createFeedRequests.length) {
                const promises = createFeedRequests.map((request) => request(pageSize));
                for (let i = 0; i < promises.length; i++) {
                    try {
                        const feed = await promises[i];
                        if (isVideoFeed(feed)) {
                            if (feed.feed_items.length) {
                                const updatedFeed = {
                                    ...feed,
                                    feed_items: feed.feed_items.map((v) => {
                                        const { variant, video } = { ...v };
                                        if (params.force_replay) {
                                            video.live_stream_replay_enabled = true;
                                        }
                                        return { variant, video };
                                    }),
                                };
                                setState((prevState) => ({
                                    ...prevState,
                                    loading: false,
                                    feed: updatedFeed,
                                    config: configs[i],
                                }));
                                return updatedFeed;
                            }
                        }
                        else if (feed.playlists.length) {
                            setState((prevState) => ({
                                ...prevState,
                                loading: false,
                                feed,
                                config: configs[i],
                            }));
                            return feed;
                        }
                    }
                    catch (e) { }
                }
                throw new Error('No configs returned a valid feed');
            }
        }
        catch (e) {
            params.target &&
                customEvent({
                    data: { detail: 'error', name: params.name },
                    target: params.target,
                    type: [EventType.FW_ERROR, EventType.FW_EMBEDFEED_ERROR],
                });
            setState({
                loading: false,
                feed: null,
                error: e,
                config: null,
            });
        }
    }, [configs, createFeedRequests, pageSize, params]);
    /**
     * Trigger feed creation (API request).
     * Note: Make sure the custom event only fires one time.
     */
    const contentReadyDispatched = useRef(false);
    useEffect(() => {
        if (feed) {
            return;
        }
        const handleSetup = async () => {
            performance?.mark(KEY_VIDEOS_STARTED);
            const feed = await createFeed();
            if (!isVideoFeed(feed)) {
                return;
            }
            if (!contentReadyDispatched.current && params.target) {
                customEvent({
                    data: {
                        feed: feed.feed_items,
                        name: params.name,
                    },
                    type: EventType.FW_EMBEDFEED_CONTENTREADY,
                    target: params.target,
                });
                contentReadyDispatched.current = true;
            }
            performance?.mark(KEY_FIRST_VIDEOS_RECEIVED);
            performance?.measure(KEY_FIRST_VIDEOS_RECEIVED, KEY_VIDEOS_STARTED);
        };
        handleSetup();
    }, [params.target, feed, createFeed, performance, params]);
    const hasMore = useMemo(() => {
        if (feed && isVideoFeed(feed)) {
            // [ZE-349]: Feed items should be defaulted to [], however given the sentry logs it seems that in rare cases
            // feed_items is undefined. As a result I added ?.length check. Once we verify this was the root cause we can
            // dig deeper on why this values type is not as expected: Followup task [GROW-1177]
            if (maxVideos && maxVideos <= (feed.feed_items?.length ?? 0)) {
                return false;
            }
            else if (feed.next_page) {
                return true;
            }
        }
        return false;
    }, [feed, maxVideos]);
    const loadMore = useCallback(async (countOfNextVideos) => {
        if (countOfNextVideos > 3 || !hasMore || !isVideoFeed(feed)) {
            return;
        }
        try {
            if (!feed?.next_page ||
                loading ||
                (maxVideos && feed?.feed_items && feed.feed_items.length >= maxVideos)) {
                return;
            }
            const pageSize = Math.min(DEFAULT_PAGE, maxVideos
                ? maxVideos - (feed?.feed_items ? feed?.feed_items.length : 0)
                : Infinity);
            const feedWithNewItems = feed
                ? await api.getMoreFeedItems(feed, pageSize)
                : null;
            setState((state) => ({
                ...state,
                feed: {
                    ...feed,
                    ...feedWithNewItems,
                    feed_items: [
                        ...(feed?.feed_items ?? []),
                        ...(feedWithNewItems?.feed_items ?? []),
                    ],
                },
            }));
        }
        catch (e) {
            if (e?.status === 404) {
                await createFeed();
            }
            else {
                setState({
                    loading: false,
                    feed,
                    error: e,
                    config: null,
                });
            }
        }
    }, [hasMore, feed, loading, maxVideos, api, createFeed]);
    const videos = useMemo(() => {
        if (!isVideoFeed(feed)) {
            return [];
        }
        const videosToHydrate = (feed?.feed_items || [])
            .filter((item) => !!item.video)
            .map((item) => {
            item.video.variant = item.variant;
            return item.video;
        });
        return (updateFeedItemsWithHydratedPartials(videosToHydrate, hydratedVideos) ?? []);
    }, [feed, hydratedVideos]);
    const playlists = useMemo(() => {
        if (feed?.playlists) {
            return feed.playlists;
        }
        return [];
    }, [feed]);
    return [
        { error, videos, loading, playlists, feed, config: winningConfig },
        { loadMore },
    ];
};
