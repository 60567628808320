import { useCallback, useEffect, useRef, useState } from 'react';
import { EventType } from '@core/customEvent';
import { PostmessageSentinel } from '@core/customEvent/postMessageWrapper';
import { useContextTranslation } from '@core/i18n';
import { useSessionStorage } from '@core/storage/hooks';
import { ABLE_TO_IFRAMEIZE } from './constants';
import { enterPIP, exitPIP } from './controls';
import { isInPIPIframe } from './isInPIPIframe';
import { setWindowHref } from './setWindowHref';
/**
 *  Hook providing the Picture-in-Picture (PIP) controls and state.
 */
export const usePIPControls = (props) => {
    const { pip_observe: observeDOM, pip_outbound_target: outboundTarget } = props.params || {};
    const enabled = props.enabled && !isInPIPIframe();
    const { t } = useContextTranslation();
    const [isAbleToIframize, setIsAbleToIframize] = useSessionStorage(ABLE_TO_IFRAMEIZE, true);
    const [isIframized, setIsIframized] = useState(false);
    const desiredHrefRef = useRef(undefined);
    const [latestHref, setLatestHref] = useState(window.location.href);
    const onLocationChange = useCallback((href) => {
        setLatestHref(href);
    }, []);
    /**
     * Set the flag to indicate successful PIP iframe load.
     * Note: `load` event on iframe is not reliable since many
     * pages can be in a loading state for a very long time.
     * See `message` event listener below.
     */
    const onLoad = useCallback(() => {
        setIsIframized(true);
    }, []);
    /**
     * Handle error from PIP controller.
     */
    const onError = useCallback(() => {
        setIsIframized((wasIframized) => {
            if (wasIframized === false) {
                setIsAbleToIframize(false);
                // If we were not able to iframeize,
                // we need to restore the desired href.
                if (desiredHrefRef.current) {
                    setWindowHref(desiredHrefRef.current);
                }
            }
            return false;
        });
    }, [setIsAbleToIframize]);
    const enter = useCallback((href) => {
        desiredHrefRef.current = href;
        const src = href || window.location.href;
        const scrollY = href !== window.location.href ? 0 : window.scrollY;
        if (isAbleToIframize && enabled) {
            enterPIP({
                onError,
                onLoad,
                onLocationChange,
                options: { observeDOM, outboundTarget },
                scrollY,
                src,
                title: t('player:Picture in Picture frame'),
            });
        }
        else if (src !== window.location.href) {
            setWindowHref(src);
        }
    }, [
        isAbleToIframize,
        enabled,
        onError,
        onLoad,
        onLocationChange,
        observeDOM,
        outboundTarget,
        t,
    ]);
    const exit = useCallback(() => {
        exitPIP();
    }, []);
    /**
     * Because `load` event on PIP iframe can take a long time to fire,
     * we listen to FW script from PIP iframe to fire "pip ready" message
     * to its parent to indicate a successful load.
     */
    useEffect(() => {
        const onMessage = (event) => {
            if (event.data.sentinel === PostmessageSentinel.INTERNAL &&
                event.data.action === EventType.FW_PIP_READY) {
                setIsIframized(true);
            }
        };
        window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage);
    }, []);
    return {
        isIframized,
        isAbleToIframize,
        latestHref,
        enter,
        exit,
    };
};
