import type { Api_Video } from '@core/api'
import type { IAnyWidgetParams } from '@core/types'
import type { ICommonState } from '@core/widgets/store/common'
import { initialState as commonInitialState } from '@core/widgets/store/common'
import {
  extractPageUrlFromQuerystring,
  extractStartTimeFromQuerystring,
} from '@embed/helpers'
import { getNextJsPublicEnv } from '@src/helpers'

export type IPWAState = ICommonState & {
  params: IAnyWidgetParams
  videos: Record<string, Api_Video>
}

export const initialState: IPWAState = {
  ...commonInitialState,
  params: {
    ...commonInitialState.defaultParams,
    api_host: getNextJsPublicEnv('API_HOST'),
    api_host_ads: getNextJsPublicEnv('API_HOST_ADS'),
    api_host_tracking: getNextJsPublicEnv('PIXEL_HOST'),
    api_host_tracking_legacy: getNextJsPublicEnv('PIXEL_NABOO_HOST'),
    location: typeof window !== 'undefined' ? window.location : undefined,
    referrer: typeof window !== 'undefined' ? document.referrer : undefined,
    page_url:
      typeof window !== 'undefined'
        ? extractPageUrlFromQuerystring(window.location) || undefined
        : undefined,
    per_page: 15,
    placement: 'fullscreen',
    start_time:
      typeof window !== 'undefined'
        ? extractStartTimeFromQuerystring(window.location)
        : undefined,
    widget_id: 'player',
    player_tap_to_unmute: true,
    player_minimize: false,
    pip: false,
  },
  videos: {},
}
