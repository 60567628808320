import type { NamedSet } from 'zustand/middleware/devtools'

import type { PlayerStateSync } from '@core/player/types'

import { closePlayerStates } from './helpers'
import type { PlayerGlobalState } from './types'

/**
 * Actions for shared player state
 */
export function createPlayerActions(set: NamedSet<PlayerGlobalState>) {
  return {
    setPlayerState: (playerState: PlayerStateSync) => {
      set(
        ({ playerStates }) => {
          const widgetId = playerState.widget_id

          return {
            playerStates: {
              ...closePlayerStates(playerStates, playerState),
              [widgetId]: playerState,
            },
          }
        },
        false,
        'playerStateSet',
      )
    },
    unsetPlayerState: (widgetId: string) => {
      set(
        (state) => {
          const { [widgetId]: _, ...playerStates } = state.playerStates || {}

          return {
            playerStates,
          }
        },
        false,
        'playerStateUnset',
      )
    },

    /** Livestream relate global actions */
    setLivestreamJoinEventTracked: (videoId: string) => {
      set(
        (state) => {
          return {
            isLivestreamJoinEventTrackedByVideoId: {
              ...state.isLivestreamJoinEventTrackedByVideoId,
              [videoId]: true,
            },
          }
        },
        false,
        'setLivestreamJoinEventTracked',
      )
    },
    unsetLivestreamJoinEventTracked: (videoId: string) => {
      set(
        (state) => {
          return {
            isLivestreamJoinEventTrackedByVideoId: {
              ...state.isLivestreamJoinEventTrackedByVideoId,
              [videoId]: false,
            },
          }
        },
        false,
        'unsetLivestreamJoinEventTracked',
      )
    },
    setUserMuted: (isUserMuted: boolean) => {
      set({ isUserMuted }, false, 'setUserMuted')
    },
  }
}
