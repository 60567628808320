export enum PlayerLayout {
  MINIMIZED = 'minimized', // Floating player
  FULLSCREEN = 'fullscreen', // Fullscreen player
  DEFAULT = 'default', // Inline player embedded into page
}

export enum VideoPlayerOpenReason {
  CLOSE_ME = 'close_me',
  DEFAULT = 'default',
  DOCK = 'dock',
  CONFIG = 'config', // pinned or fullscreen
  PROGRAMMATIC = 'programmatic', // share or soft pip
  INTERACTION = 'interaction',
}

export const openReasonPriority: Record<VideoPlayerOpenReason, number> = {
  [VideoPlayerOpenReason.CLOSE_ME]: 1,
  [VideoPlayerOpenReason.DEFAULT]: 2,
  [VideoPlayerOpenReason.DOCK]: 3,
  [VideoPlayerOpenReason.CONFIG]: 4,
  [VideoPlayerOpenReason.PROGRAMMATIC]: 5,
  [VideoPlayerOpenReason.INTERACTION]: 6,
}

export interface PlayerStateSync {
  widget_id: string
  channel?: string
  playlist?: string
  hashtag?: string
  videoId?: string
  layout: PlayerLayout
  openReason: VideoPlayerOpenReason
}
