import { PlayerLayout } from '@core/player/types';
/**
 * Creates object with actions for EmbedFeed store
 */
export const createEmbedFeedActions = (set, get) => ({
    resetPlaceholders: () => {
        set({ placeholders: [] });
    },
    setThumbnailLoaded: (video) => {
        const { thumbnailIdsLoaded } = get();
        if (!thumbnailIdsLoaded[video.encoded_id]) {
            set((state) => ({
                thumbnailIdsLoaded: {
                    ...state.thumbnailIdsLoaded,
                    [video.encoded_id]: true,
                },
            }), false, 'setThumbnailLoaded');
        }
    },
    registerThumbnailImpression: (video) => {
        const { thumbnailIdsLoaded: loaded, thumbnailImpressionIdsReportSuccess: success, } = get();
        if (loaded[video.encoded_id] && !success[video.encoded_id]) {
            set((state) => ({
                thumbnailImpressionVideoReportQueue: [
                    ...state.thumbnailImpressionVideoReportQueue,
                    video,
                ],
            }), false, 'registerThumbnailImpression');
        }
    },
    processThumbnailImpressionQueue: (videos) => {
        if (videos.length) {
            const newSuccess = videos.reduce((acc, video) => {
                return {
                    ...acc,
                    [video.encoded_id]: true,
                };
            }, {});
            set((state) => ({
                thumbnailImpressionVideoReportQueue: [],
                thumbnailImpressionIdsReportSuccess: {
                    ...state.thumbnailImpressionIdsReportSuccess,
                    ...newSuccess,
                },
            }), false, 'processThumbnailImpressionQueue');
        }
    },
    showVideoPlayer: ({ video, playlist, }) => {
        set(() => ({
            player: {
                video,
                playlist,
                isOpen: true,
                layout: PlayerLayout.FULLSCREEN,
            },
        }), false, 'showVideoPlayer');
    },
    setVideoPlayerLayout: (layout) => {
        set((state) => ({
            player: { ...state.player, layout },
        }), false, 'setVideoPlayerLayout');
    },
    hideVideoPlayer: () => {
        set((state) => ({
            player: {
                ...state.player,
                isOpen: false,
                layout: PlayerLayout.DEFAULT,
            },
        }), false, 'hideVideoPlayer');
    },
    registerInViewport: (video) => {
        const { inViewportIds } = get();
        if (!inViewportIds[video.encoded_id]) {
            set((state) => ({
                inViewportIdsReportQueue: [
                    ...state.inViewportIdsReportQueue,
                    video.encoded_id,
                ],
                inViewportIds: {
                    ...state.inViewportIds,
                    [video.encoded_id]: true,
                },
            }), false, 'registerInViewport');
        }
    },
    unregisterInViewport: (video) => {
        const { inViewportIds } = get();
        if (inViewportIds[video.encoded_id]) {
            set((state) => ({
                inViewportIds: {
                    ...state.inViewportIds,
                    [video.encoded_id]: false,
                },
            }), false, 'unregisterInViewport');
        }
    },
    /**
     * Registers video ids as successfully processed and resets the queue.
     *
     * @param newInViewportIds - Ids that are considered new obtained from the queue
     */
    processInViewportQueue: (newInViewportIds) => {
        if (newInViewportIds.length) {
            set((state) => ({
                inViewportIdsReportQueue: [],
                inViewportIdsReportSuccess: [
                    ...state.inViewportIdsReportSuccess,
                    ...newInViewportIds,
                ],
            }), false, 'processInViewportQueue');
        }
    },
    /**
     * Explicitly set video thumbnail to autoplay. Used
     * when user hovers over a video thumbnail.
     */
    setAutoplay: (videoId) => {
        const { inViewportIds, autoplayId } = get();
        if (inViewportIds[videoId] && autoplayId !== videoId) {
            set({ autoplayId: videoId, pausedAutoplayId: null }, false, 'setAutoplay');
        }
    },
    /**
     * Resets autoplay so none of the videos is playing.
     */
    pauseAutoplay: () => {
        set({
            autoplayId: null,
            pausedAutoplayId: get().autoplayId ?? get().pausedAutoplayId,
        }, false, 'pauseAutoplay');
    },
});
